import React from "react";
import Container from '../components/styled/Container'

const RegularPage = () => (
  <Container>
    <h1>Regular Page</h1>
    <p>
      Nunc ultricies ante quam, vel congue urna semper id. In a blandit lorem.
      Integer eros libero, varius at dui efficitur, efficitur iaculis lorem. Ut
      commodo risus id egestas porttitor. Proin at dignissim risus, et egestas
      enim. Pellentesque et enim vel diam molestie interdum quis at ante. Cras
      viverra sit amet ante eget bibendum. Integer dictum, turpis vitae dapibus
      egestas, ipsum nisl ullamcorper magna, at gravida lectus sapien sed orci.
      Cras neque mi, efficitur sit amet ipsum non, mollis gravida eros. Mauris
      quis euismod quam. Curabitur nunc odio, condimentum at velit nec,
      fringilla gravida diam. Nullam vitae porttitor est.
    </p>
    <p>
      Donec tristique condimentum dictum. Maecenas hendrerit lorem at ornare
      dictum. Fusce faucibus libero eget sollicitudin porttitor. Nunc in
      hendrerit ipsum. Phasellus convallis maximus leo a venenatis. Suspendisse
      tristique mollis eros, ac ullamcorper enim mollis sed. Quisque mauris leo,
      lobortis ut faucibus sed, porttitor non odio. Phasellus feugiat, velit sit
      amet tempus scelerisque, massa mi commodo justo, et aliquet magna lorem id
      turpis. Sed a ligula luctus, posuere dui id, placerat mi. Sed iaculis,
      arcu eget viverra aliquet, ante nibh volutpat mauris, sit amet finibus
      odio erat non dui. Vestibulum aliquam condimentum quam, nec cursus erat
      auctor sit amet. Aenean in purus neque.
    </p>
    <p>
      Curabitur condimentum dui sem, a porta ligula cursus vitae. Nulla a ex id
      erat viverra luctus. Maecenas facilisis vulputate luctus. Nam id ipsum
      diam. Cras non tortor ultricies, luctus enim ut, accumsan enim. Nam
      suscipit enim vel pellentesque iaculis. Suspendisse non odio sit amet elit
      vestibulum facilisis a eget metus. Nam dapibus urna et cursus euismod.
      Nunc consectetur felis tortor, et luctus purus tincidunt ut. Nunc diam
      libero, tincidunt ac magna id, finibus aliquet mi. Vivamus quis gravida
      leo. Suspendisse rutrum consectetur ante, nec ullamcorper nisl feugiat eu.
      Sed mollis, justo sit amet convallis pulvinar, nisi eros placerat orci,
      non efficitur metus ex ut massa. Fusce ullamcorper blandit leo, eu pretium
      ligula bibendum eu. In non hendrerit ante. Aliquam erat volutpat.
    </p>
    <p>
      Nullam ornare eleifend nunc, ut consectetur massa mattis quis. In id
      ultricies leo, eu facilisis nibh. Maecenas sed ex placerat, tincidunt nisi
      at, hendrerit nulla. Maecenas fringilla magna ac sapien aliquet volutpat.
      Praesent pulvinar lacus sit amet egestas porttitor. Praesent tempus elit
      tortor, nec pharetra urna molestie non. Quisque tempus enim augue, vitae
      molestie ipsum pretium at. Donec non velit sed sem maximus suscipit.
      Curabitur non condimentum nisi, in mollis nibh.
    </p>
  </Container>
);

export default RegularPage;
